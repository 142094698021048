import React, { useEffect, useState, useCallback,useMemo } from "reactn"
import { useLocation } from "react-router-dom"
import Header from "src/markup/Layout/Header"
import styled from "styled-components"
import { omit, keyBy } from "lodash"
import { toast } from "react-toastify"
import * as S from "./styles"

import SidebarCandidate from "src/markup/Layout/SidebarCandidate"
import Footer from "src/markup/Layout/Footer"
import CardCVComponent from "src/markup/Pages/Candidate/CandidateMyCV/CardCV"
import { deleteCv, getMyListCVCandidate } from "src/services/api"
import { CV_STATUS, SORT_BY } from "src/constants"
import { Loading } from "src/markup/Element/Loading"
import { ConfirmModal } from "src/markup/Components/Modals/ConfirmModal"

const Content = styled.div`
  margin-bottom: 50px;
  margin-top: 65px;
  padding: 0 20px;

  @media (min-width: 992px) {
    padding: 0 50px;
  }
`

const CandidateMyCV = () => {
  const [listCV, setListCV] = useState([])
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState({ showConfirmModal: false })

  const getMyListCvs = useCallback(async () => {
    try {
      setLoading(true)
      const res = await getMyListCVCandidate({
        sort_by: SORT_BY.DESC,
      })

      if (res.length) setListCV(res)
    } catch (error) {
      return error
    } finally {
      setLoading(false)
    }
  }, [])

  const filteredOffers = useMemo(() => {
    return listCV.filter((of) => (location.pathname === "/candidate/mycv/draft" ? of.status === CV_STATUS.DRAFT : of.status === CV_STATUS.PUBLISHED))
  }, [location.pathname, listCV])

  useEffect(() => {
    getMyListCvs()
  }, [getMyListCvs])

  const handleDeleteCv = async (id) => {
    const shadowCvs = keyBy(listCV, "id")
    const res = await deleteCv(id)
    toast.success(res.message)
    setListCV(Object.values(omit(shadowCvs, id)))
    setModal({ showConfirmModal: false })
  }

  const onDelete = (id) => {
    setModal({
      showConfirmModal: true,
      onYes: () => handleDeleteCv(id),
      onNo: () => setModal({ showConfirmModal: false }),
      Description: "Êtes-vous sûr de vouloir supprimer le CV ?",
    })
  }

  return (
    <div className="page-wraper">
      <Header candidate />

      <div className="container-content">
        <div className="d-flex" style={{ flexWrap: "nowrap" }}>
          <SidebarCandidate />
          {loading && <Loading />}
          <Content className="col-11 col-xl">
            <div className="d-flex justify-content-center">
              <S.Heading>{location.pathname === "/candidate/mycv/draft" ? "BROUILLONS" : "Mes CV"}</S.Heading>
            </div>
            <hr className="v3-separator pb-xl-3" />

            <div>
              <CardCVComponent
                listCV={filteredOffers}
                onDelete={onDelete}
                totalCV={listCV.length}
              />
            </div>
          </Content>
        </div>
      </div>

      <Footer />
      <ConfirmModal {...modal} />
    </div>
  )
}

export default CandidateMyCV
