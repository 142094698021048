import { API_URL } from "src/constants";
import axios from "./axios";

export const createCVCandidate = (payload) => {
  return axios
    .post(`${API_URL}/candidate/create-cv`, payload)
}

export const getMyListCVCandidate = (params) => {
  return axios
    .get(`${API_URL}/cvs/list`, { params })
    .then((data) => data)
    .catch((err) => err)
}

export const getProfileById = (id) => axios.get(`${API_URL}/user/${id}`);

export const updateProfile = (payload) => axios.patch(`${API_URL}/user-v2`, payload);

export const getAllOffers = (params) => {
  return axios
    .get(`${API_URL}/offers`, { params })
    .then((data) => data)
    .catch(console.error)
}

export const getOfferById = (id) => {
  return axios
    .get(`${API_URL}/offers/${id}`)
    .then((data) => data)
    .catch(console.error)
}

export const recruiterCreateOffer = (payload) => axios.post(`${API_URL}/recruiter/create-offer`, payload);

export const recruiterUpdateOffer = (payload) => axios.post(`${API_URL}/offers/update`, payload);

export const recruiterGetListOffers = (params) => axios.get(`${API_URL}/recruiter/offers`, { params });

export const recruiterGetListOffersDetail = (id) => axios.get(`${API_URL}/offers/${id}`);

export const apiEditStatusCv = (payload) => {
  return axios
    .post(`${API_URL}/edit-status`, payload)
    .then((data) => data)
    .catch(console.error)
}

export const requestSearchCv = (payload) => {
  return axios
    .post(`${API_URL}/searchCV`, payload)
    .then((data) => data)
    .catch(console.error)
}

export const apiCVDetail = (id) => {
  return axios
    .get(`${API_URL}/cvs/${id}`)
    .then((data) => data)
    .catch(console.error)
}

export const requestSearchJob = (payload) => {
  return axios
    .post(`${API_URL}/searchOffer`, payload, { params: { limit: 10 } })
    .then((data) => data)
    .catch(console.error)
}

export const recruiterCreateAgenda = (payload) => {
  return axios
    .post(`${API_URL}/agenda/create`, payload)
    .then((data) => data)
    .catch(console.error)
}

export const recruiterEditAgenda = (payload) => {
  const { id, ...rest } = payload
  return axios.patch(`${API_URL}/agenda/${id}`, rest);
}

export const getListContacts = () => axios.get(`${API_URL}/messages/list-contacts`);

export const sendMessage = (payload) => axios.post(`${API_URL}/messages/send-message`, payload);

export const getListMessage = (params) => axios.get(`${API_URL}/messages/list`, { params });

export const searchMessage = (params) => axios.get(`${API_URL}/messages/search`, { params });

export const actionMessage = (payload) => {
  const params = {
    type_action: payload.type_action,
    type_message: payload.type_message,
  }
  axios.post(`${API_URL}/messages/action-message/${payload.id}`, {}, { params })
}

export const readMessage = (id) => axios.post(`${API_URL}/messages/read/${id}`);

export const saveSearchCv = (payload) => axios.post(`${API_URL}/search/save-cv`, payload);

export const saveSearchOffer = (payload) => axios.post(`${API_URL}/search/save-offer`, payload);

export const recruiterListAgenda = (params = {}) => axios.get(`${API_URL}/agenda/list`, { params });

export const getCvsInfos = (payload) => axios.post(`${API_URL}/cvs-infos`, payload);

export const getOfferInfos = (payload) => axios.post(`${API_URL}/offer-infos`, payload);

export const getCards = () => axios.get(`${API_URL}/cards`);

export const deleteCard = (id) => axios.delete(`${API_URL}/card/${id}`);

export const payment = (payload) => axios.post(`${API_URL}/order/payment`, payload);

export const getOrderById = (id) => axios.get(`${API_URL}/order/${id}`);

export const addInformationPayment = (payload) => axios.post(`${API_URL}/order/add-information-payment`, payload);

export const addCard = (payload) => axios.post(`${API_URL}/card`, payload);

export const getListSavedSearch = () => axios.get(`${API_URL}/search/get`, { params: { sort_by: 'DESC' } });

export const deleteSavedSearch = (id) => axios.delete(`${API_URL}/search/delete/${id}`);

export const updateSavedSearch = (id, payload) => axios.put(`${API_URL}/search/update/${id}`, payload);

export const createOrderInfo = (payload) => axios.post(`${API_URL}/order`, payload);

export const getOrderList = () => axios.get(`${API_URL}/orders`, { params: { sort_by: 'ASC' } });

export const uploadImage = (payload) => axios.post(`${API_URL}/upload-image`, payload);

export const getListFavoriteOffer = () => axios.get(`${API_URL}/favorite/offers/list`);

export const handleFavoriteOffer = (id) => axios.post(`${API_URL}/favorite/offer/${id}`);

export const getLiaisons = () => axios.get(`${API_URL}/liaisons`);

export const recruiterDeleteAgenda = (id) => axios.delete(`${API_URL}/agenda/${id}`);

export const recruiterDetailAgenda = (id) => axios.get(`${API_URL}/agenda/detail/${id}`);

export const deleteOffer = (id) => axios.delete(`${API_URL}/offer/${id}`);

export const deleteCv = (id) => axios.delete(`${API_URL}/cv/${id}`);

export const candidateListAgenda = (params = {}) => axios.get(`${API_URL}/agenda/candidate/list`, { params })

export const updateCv = (payload) => axios.post(`${API_URL}/cvs/update`, payload)

export const verifyCodeByEmail = (params) => axios.get(`${API_URL}/auth/check-email`, { params });

export const sendOtpCode = (params) => axios.post(`${API_URL}/auth/send-otp-code`, params);

export const forgotNewPassword = (params) => axios.post(`${API_URL}/auth/forgot-new-password`, params);

export const apiCreatePaymentIntent = (params) => axios.post(`${API_URL}/payment-intent`, params);

export const apiGetBillingInfo = () => axios.get(`${API_URL}/billing-info`)

export const apiGetMyAutoPackages = () => axios
  .get(`${API_URL}/autonomous-packages`)
  .then((data) => data)
  .catch(console.error);

export const apiCandidateEditStatusAgenda = (params) => axios.post(`${API_URL}/candidate/agenda/edit-status`, params);

export const apiGetOfferStatus = (id) => axios.get(`${API_URL}/offers/update-status-active/${id}`)

export const apiGetNotifications = () => axios.get(`${API_URL}/notifications`);

export const apiReadNotifications = (params) => axios.post(`${API_URL}/read-notifications`, params);
