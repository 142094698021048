import React, { useCallback, useGlobal } from "reactn"
import { useHistory } from "react-router-dom"
import { getCartFromStorage, saveCartToStorage } from "src/services/auth"
import { CardBasic, CardNormal, CardSpecial } from "../components"
import * as S from "./styles"

export const PackagePlan = () => {
  const history = useHistory()
  const [formulas] = useGlobal("formulas")
  const { packages } = formulas ?? {};

  const addCart = useCallback(
    (value) => {
      let cart = getCartFromStorage()

      if (cart.length) {
        const cartFilter = cart.filter(c => c.ID === value.ID)
        if (cartFilter.length > 0) {
          for (let i = 0; i < cart.length; i++) {
            const element = cart[i];
            if (element.ID === value.ID) {
              cart[i].count = cart[i].count + 1;
            }
          }
        } else {
          cart = [...cart, { ...value, count: 1 }]
        }
      } else {
        cart = [...cart, { ...value, count: 1 }];
      }

      saveCartToStorage(cart);
      history.push("/recruiter/cart")
    },
    [history]
  )

  return (
    <S.Wrapper>
      <div className="d-flex justify-content-center">
        <S.Heading>Nos formules</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />

      <div className="d-lg-flex align-items-center d-block flex-wrap flex-xl-nowrap justify-content-around">
        <div className="mr-4">
          <CardBasic onAddCart={() => addCart(packages[0])} />
        </div>
        <S.PackageFocus className="pl-4 pr-4">
          <div className="heading">Avec nos consultants, optez pour la formule :</div>
          <S.ResponsivePackages>
            <CardNormal onAddCart={() => addCart(packages[1])} />
            <CardSpecial onAddCart={() => addCart(packages[2])} />
            <CardSpecial
              benefitList={[
                {
                  html: '<span class="text-blue"> Dernier entretien en votre présence en vue de l\'embauche</span>',
                },
              ]}
              namePackage="SPORTIVE"
              packageMix="FORMULE ACTIVE"
              price="6000"
              onAddCart={() => addCart(packages[3])}
            />
          </S.ResponsivePackages>
        </S.PackageFocus>
      </div>
    </S.Wrapper>
  )
}
