import React, { useMemo, useCallback, useDispatch, useEffect, useGlobal, useState } from "reactn"
import { isEmpty } from "lodash"
import { toast } from "react-toastify"
import { CV_STATUS, ROLE, SORT_BY, FORMAT_DATE } from "src/constants"
import { ReactComponent as StartIcon } from "src/images/icons/ic-start.svg"
import { ReactComponent as IconNote } from "src/images/icons/note.svg"
import IconSend from "src/images/icons/send.svg"
import { MecButton } from "src/markup/Components"
import { SelectCV } from 'src/markup/Pages/SearchPage/Candidate/components'
import { apiEditStatusCv, getMyListCVCandidate, handleFavoriteOffer } from "src/services/api"
import { isCandidate, isRecruiter, isAnonymous } from "src/services/auth"
import { useGetFavoriteOffer } from "src/markup/Pages/SearchPage/Candidate/hooks/useGetFavoriteOffer"
import moment from "moment"
import { useGetJobLiaisons } from "src/markup/Pages/SearchPage/Candidate/hooks/useGetJobLiaisons"
import { Loading } from "src/markup/Element/Loading"
import { useHistory } from "react-router-dom"
import { ReactComponent as Wrench } from 'src/images/icons/wrench.svg'

export const GuestFooter = ({ onApply, offer }) => (
  <div className="d-flex align-items-center justify-content-between w-100">
    <div className="text-right">
      <img src={IconSend} alt="icon-send" className="mr-2" />
      <span>Offre publiée le {offer.date}</span>
    </div>
    <div className="d-flex justify-content-end">
      <MecButton
        style={{ display: "block" }}
        icon={IconNote}
        widthIcon={20}
        width={300}
        type="primary h-100"
        className="site-button site-button-gap radius-xl"
        onClick={onApply}
      >
        Vite, je postule !
      </MecButton>
    </div>
  </div>
)

export const CandidateFooter = ({ offer, otherCandidateProps }) => {
  const CANDIDATE = isCandidate()
  const [user] = useGlobal("user")
  const [myCvs, setMyCvs] = useState([])
  const history = useHistory();

  const [cvSelected, setCvSelected] = useState(null)
  const [isSending, setIsSending] = useState(false)
  const [adding, setAdding] = useState(false)

  // TODO : this function is defined 3 times -> hook?
  const getMyCvs = useCallback(async () => {
    if (CANDIDATE) {
      const res = await getMyListCVCandidate({
        status: CV_STATUS.PUBLISHED,
        sort_by: SORT_BY.DESC,
      })

      if (res?.length > 0) {
        const optionsCvs = res.map((cv) => ({
          value: cv.id,
          label: cv.name_cv,
        }))
        setMyCvs(optionsCvs)
      }
    }
  }, [CANDIDATE])

  useEffect(() => {
    getMyCvs()
  }, [getMyCvs])

  const { favoriteIdList, setForce } = useGetFavoriteOffer()

  const isFavorite = useMemo(() => favoriteIdList.includes(offer.id), [favoriteIdList, offer.id])

  const onChangeSelect = async (option) => {
    const payload = {
      cvId: option.value,
      offerId: offer.id,
      recruiterId: offer.userId,
      candidateId: user.id,
    }
    setCvSelected(payload)
  }

  const onApply = async () => {
    setIsSending(true)
    try {
      await apiEditStatusCv(cvSelected)
      otherCandidateProps.setForce(Date.now());
      toast.success(`Candidature envoyée avec succès`, {
        autoClose: 2000,
        theme: "colored",
      })
    } catch (error) {
    } finally {
      setIsSending(false)
    }
  }

  const handleFavorite = async () => {
    try {
      setAdding(true)
      const data = await handleFavoriteOffer(offer.id)
      toast.success(data.message, {
        autoClose: 2000,
        theme: "colored",
      })
    } finally {
      setAdding(false)
      setForce(Date.now())
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="text-right">
        <img src={IconSend} alt="icon-send" className="mr-2" />
        <span>Offre publiée le {moment(offer.createdAt).format(FORMAT_DATE)}</span>
      </div>
      <div className="d-flex justify-content-end align-items-center col-lg-9 col-12">
        <div className="pr-2">
          <MecButton
            type="primary"
            icon={StartIcon}
            widthIcon={15}
            height={35}
            onClick={handleFavorite}
            disabled={adding}
          >
            {isFavorite ? "Supprimer des favoris" : "Mettre en favoris"}
          </MecButton>
        </div>
        {
          !otherCandidateProps.isOfferJoined ? (
            <>
              <div className="pr-2">
                <SelectCV options={myCvs} onChange={onChangeSelect} />
              </div>
              <div>
                <MecButton
                  type="primary"
                  height={35}
                  onClick={onApply}
                  disabled={isEmpty(cvSelected) || isSending}
                >
                  Envoyer CV
                </MecButton>
              </div>
            </>
          ) : (
            <MecButton
              icon={Wrench}
              width={100}
              height={35}
              widthIcon={20}
              type="primary"
              onClick={() => history.push('/candidate/application/sent')}
            >
              Modifier
            </MecButton>
          )
        }
      </div>
    </div>
  )
}

export const FooterOffer = (props) => {
  const onShowModal = useDispatch("showModalAuth")
  const RECRUITER = isRecruiter()
  const CANDIDATE = isCandidate()
  const ANONYMOUS = isAnonymous()

  const {
    loadingGetListOfferJoined,
    liaisonsOfferList,
    setForce
  } = useGetJobLiaisons()

  const onApply = () => {
    if (!RECRUITER || !CANDIDATE) {
      onShowModal({ payload: ROLE.CANDIDATE })
    }
  }

  const isOfferJoined = liaisonsOfferList.includes(props.offer.id)
  const otherCandidateProps = { isOfferJoined, setForce }

  return (
    <>
      {loadingGetListOfferJoined ? (
        <Loading />
      ) : (
        <>
          {CANDIDATE && <CandidateFooter {...props} otherCandidateProps={otherCandidateProps} />}
        </>
      )}
      {ANONYMOUS && <GuestFooter {...props} onApply={onApply} />}
    </>
  )
}
