import styled from "styled-components"
import { Collapse } from "reactstrap"

export const BoxContent = styled.div`
  padding: 40px;
  background: var(--white);
  color: var(--blue600);
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`

export const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
`

export const HeadingSearch = styled.p`
  color: var(--orange300);
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 10px;

  @media (max-width: 992px) {
    font-size: 30px;
  }
`

export const FormWrapper = styled.div`
  position: relative;
  .label {
    font-weight: 800;
    font-family: "Raleway";
  }
`

export const Icon = styled.div`
  width: 26px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
  ${(props) => props.isOpen && "animation:change 0.3s linear forwards"};
  @keyframes change {
    50% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
`

export const HeadingOrange = styled.div`
  color: var(--orange300);
  font-weight: ${({ bold }) => (bold ? "800" : "400")};
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};

  ${({ isBefore }) =>
    isBefore &&
    `
      &::before {
        content:" ";
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: var(--orange300);
        display: inline-block;
        margin-right: 10px;
      }
  `}
`

export const MyCollapse = styled(Collapse)`
  .collapsing {
    transition: height 1s ease;
  }
`
