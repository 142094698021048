import styled, { css } from "styled-components"

const CustomLabelCommon = css`
  .label {
    font-weight: 800;
    font-size: 19px;
    line-height: 28px;
    color: #023f73;
    display: flex;
  }

  .sub-label {
    color: #023f73;
    font-style: italic;
  }

  .custom-radio {
    min-width: 50px;
    margin-left: 15px;
    height: 20px;
  }

  .label-disabled {
    color: #bec7cc;
    circle {
      fill: #bec7cc;
    }
  }

  .label-required:after {
    content: "*";
    font-weight: 500;
    font-size: 53px;
    color: #ff7200;
    padding-left: 15px;
  }
`

export const StyledForm = styled.div`
  width: 100%;
  padding: 0 50px;

  ${CustomLabelCommon}

  .add-experience {
    position: initial;
    right: -100%;

    svg {
      transform: scale(0.7);
      cursor: pointer;
    }
    .label {
      font-weight: 500;
      margin-left: 15px;
      cursor: pointer;
    }
  }

  .sub-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 44px;
    color: #023f73;
  }

  .w-250 {
    width: 250px;
  }

  @media (min-width: 1200px) {
    .add-experience {
      position: relative;
    }
  }
`
