import React from "reactn"
import moment from "moment"
import { FormGroup } from "reactstrap"

import * as S from "./styles"
import { ACTION_MESSAGE, FORMAT_DATE } from "src/constants"
import { MecButton } from "src/markup/Components"
import { ReactComponent as IconRemove } from "src/images/icons/icon-remove.svg"

const MessageDetail = ({ message, handleActionMessage, actionLoading }) => {
  return (
    <S.MessageArchived>
      <S.Header>
        <div className="row">
          <div className="col-4 d-flex pt-3">
            <div className="label text-align-last-left label-required mr-2">De :</div>
            <div style={{ flex: 1 }}>{message.group_messages_users[0].email}</div>
          </div>

          <div className="col-5 d-flex pt-3">
            <div className="label text-align-last-left label-required mr-2">Objet :</div>
            <div style={{ flex: 1 }}>{message.object_title}</div>
          </div>

          <div className="col-3 d-flex pt-3">
            <div className="label text-align-last-left label-required mr-2">Date :</div>
            <div style={{ flex: 1 }}>{moment(message.createdAt).format(FORMAT_DATE)}</div>
          </div>
        </div>
        <hr style={{ position: "initial" }} />
      </S.Header>
      <S.Content className="row">
        <FormGroup className="col-12 m-0">
          <div style={{ flex: 1 }}>{message.last_message}</div>
        </FormGroup>
      </S.Content>

      <S.Footer className="row justify-content-end">
        <MecButton
          icon={IconRemove}
          height={44}
          width={200}
          type="primary"
          className="site-button site-button-gap radius-xl"
          onClick={(e) => handleActionMessage({ e: e, messageId: message.id, actionType: ACTION_MESSAGE.DELETED })}
        >
          {actionLoading === ACTION_MESSAGE.DELETED ? "..." : "Supprimer"}
        </MecButton>
      </S.Footer>
    </S.MessageArchived>
  )
}

export default MessageDetail
