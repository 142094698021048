import React from "reactn"
import { FormGroup } from "reactstrap"
import moment from "moment"
import { useHistory } from 'react-router-dom'

import * as S from "./styles"
import { MecButton } from "src/markup/Components"
import { ACTION_MESSAGE, FORMAT_DATE } from "src/constants"
import { ReactComponent as IconRemove } from "src/images/icons/icon-remove.svg"
import { ReactComponent as IconAnswer } from "src/images/icons/icon-answer.svg"
import { ReactComponent as IconArchive } from "src/images/icons/icon-archive.svg"

const MessageDetail = ({ message, mode, handleActionMessage, actionLoading }) => {
  const history = useHistory()
  const email = mode === "RECEIVED" ? message.group_messages_users[0].email : message.groups_users_receive[0].email
  const id = mode === "RECEIVED" ? message.group_messages_users[0].id : message.groups_users_receive[0].id

  return (
    <S.MessageReceivedAndSent>
      <S.Header>
        <div className="row">
          <div className="col-4 d-flex pt-3">
            <div className="label text-align-last-left label-required mr-2">{mode === "RECEIVED" ? "De" : "À"} :</div>
            <div style={{ flex: 1 }}>
              {email}
            </div>
          </div>

          <div className="col-5 d-flex pt-3">
            <div className="label text-align-last-left label-required mr-2">Objet :</div>
            <div style={{ flex: 1 }}>{message.object_title}</div>
          </div>

          <div className="col-3 d-flex pt-3">
            <div className="label text-align-last-left label-required mr-2">Date :</div>
            <div style={{ flex: 1 }}>{moment(message.createdAt).format(FORMAT_DATE)}</div>
          </div>
        </div>
        <hr style={{ position: "initial" }} />
      </S.Header>
      <S.Content className="row">
        <FormGroup className="col-12 m-0">
          <div style={{ flex: 1 }}>{message.last_message}</div>
        </FormGroup>
      </S.Content>

      <S.Footer className="row justify-content-end">
        <MecButton
          icon={IconAnswer}
          height={44}
          width={200}
          type="primary"
          className="site-button site-button-gap radius-xl change-fill"
          onClick={(e) => {
            e.preventDefault();
            history.push({
                pathname: '/candidate/message/new',
                state: { email, id }
            })
          }}
        >
          Répondre
        </MecButton>
        {mode === "RECEIVED" && (
          <MecButton
            icon={IconArchive}
            height={44}
            width={200}
            type="primary"
            onClick={(e) => handleActionMessage({ e: e, messageId: message.id, actionType: ACTION_MESSAGE.ARCHIVE })}
          >
            {actionLoading === ACTION_MESSAGE.ARCHIVE ? "..." : "Archiver"}
          </MecButton>
        )}
        <MecButton
          icon={IconRemove}
          height={44}
          width={200}
          type="primary"
          onClick={(e) => handleActionMessage({ e: e, messageId: message.id, actionType: ACTION_MESSAGE.DELETED })}
        >
          {actionLoading === ACTION_MESSAGE.DELETED ? "..." : "Supprimer"}
        </MecButton>
      </S.Footer>
    </S.MessageReceivedAndSent>
  )
}

export default MessageDetail
