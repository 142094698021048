import React from "react"
import Header from "src/markup/Layout/Header"

import SidebarRecruiter from "src/markup/Layout/SidebarRecruiter"
import SidebarCandidate from "src/markup/Layout/SidebarCandidate"
import Footer from "src/markup/Layout/Footer"
import { MainProvider } from "./MainContext"

export const MainLayout = ({ candidate, recruiter, children }) => {
  const SideMenu = candidate ? SidebarCandidate : SidebarRecruiter

  return (
    <MainProvider>
      <div className="page-wraper">
        <Header recruiter={recruiter} candidate={candidate} />

        <div className="container-content">
          <div className="d-flex">
            <SideMenu />
            <div className="px-md-5 px-2 py-5" style={{ flex: 1 }}>
              {children}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </MainProvider>
  )
}
