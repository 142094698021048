import { ReactComponent as IconTick } from 'src/images/icons/icon-tick-blue.svg'
import { MecButton } from 'src/markup/Components'
import * as S from 'src/markup/Pages/OurPackage/Recruiter/components/styles'
import { formatPrice } from 'src/utils';

const defaultBenefitList = [
  {
    html: '<span>1er<span class="text-orange"> entretien <span class="text-blu">approfondi</span> </span></span>',
  },
  {
    html: '<span>2ème<span class="text-orange"> sélection <span class="text-blu">des profils</span> </span></span>',
  },
]

export const CardSpecial = ({
  namePackage = 'active',
  packageMix = 'FORMULE ACCOMPAGNÉE',
  benefitList = defaultBenefitList,
  price = '3000',
  onAddCart
}) => {
  return (
    <S.Box style={{margin: 0}}>
      <div className="card-plan">
        <S.FlexColumn className="h-100">
          <div>
            <div className="card-plan__title text-center text-uppercase">
              {namePackage}
            </div>
            <div className="card-plan__benefit active">
              <div className="card-plan__benefit-package text-center text-uppercase">
                {packageMix}
              </div>
              <div className="text-center text-orange" style={{ fontSize: 60 }}>
                +
              </div>
              <div>
                {benefitList.map(({ html }, idx) => (
                  <div key={idx} className="d-flex py-2">
                    <div className="col-1 pl-0">
                      <IconTick width={20} />
                    </div>
                    <div className="card-plan__text pl-2">
                      <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="card-plan__tag text-orange text-center font-weight-bold">
            Garanti satisfait ou remboursé*
          </div>
        </S.FlexColumn>

        <div className="card-plan__price text-center">{formatPrice(price)} € HT</div>
      </div>
      <MecButton onClick={onAddCart} className="text-uppercase my-3" height={35} >
        sélectionner
      </MecButton>
    </S.Box>
  )
}
