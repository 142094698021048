import React from "reactn"
import { ReactComponent as IconTick } from "src/images/icons/icon-tick-blue.svg"
import { MecButton } from "src/markup/Components"
import * as S from "src/markup/Pages/OurPackage/Recruiter/components/styles"
import { formatPrice } from "src/utils";

const benefitList = [
  "Définir 1 profil",
  "Publier l'offre en ligne",
  "Rechercher des CV dans la CVthèque",
  "Matching de profil",
]

export const CardBasic = ({ onAddCart }) => {
  return (
    <S.Box isBg>
      <div className="card-plan">
        <div>
          <div className="card-plan__title text-center">AUTONOME</div>
          <div className="card-plan__benefit">
            {benefitList.map((benefit, idx) => (
              <div key={idx} className="d-flex align-items-center">
                <div className="col-1 pl-0">
                  <IconTick width={20} fill="none" />
                </div>
                <div className="card-plan__text pl-2 py-2">
                  <div>{benefit}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="card-plan__price text-center border-none">{`${formatPrice(60)} € HT`}</div>
      </div>
      <MecButton className="text-uppercase my-3" onClick={onAddCart}>
        sélectionner
      </MecButton>
    </S.Box>
  )
}
