import React, { useState, useMemo } from 'reactn'
import { Link, useLocation, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isAnonymous, isRecruiter, isCandidate, logout } from 'src/services/auth'

import { MecButton } from '../Components'
import { ReactComponent as UserCircle } from '../../images/icons/usercircle.svg'
import { ReactComponent as BriefCase } from '../../images/icons/briefcase.svg'
import { ReactComponent as IconArrowRight } from '../../images/icons/icon-arrow-right.svg'
import { ReactComponent as LogoutIcon } from '../../images/icons/logout.svg'

import {
  HeaderWrapper,
  LogoHeaderWrapper,
  TopMenuWrapper,
  ExtraNavWrapper,
} from 'src/css/Header'

import { productionMode } from 'src/utils'

const breadcrumb = [
  // {
  //   pathname: '/candidate/login',
  //   titleBreadcrumb: 'Authentification > Connexion',
  // },
  // {
  //   pathname: '/candidate/register',
  //   titleBreadcrumb: 'Authentification > Inscription',
  // },
  // {
  //   pathname: '/candidate/forgot-password',
  //   titleBreadcrumb: 'Authentification > Mot de passe oublié',
  // },
  {
    pathname: '/candidate/mycv',
    titleBreadcrumb: 'Mes CV > Mes CV publiés',
  },
  {
    pathname: '/candidate/mycv/create',
    titleBreadcrumb: 'Mes CV > Nouveau CV',
  },
  {
    pathname: '/candidate/mycv/draft',
    titleBreadcrumb: 'Mes CV > Brouillons',
  },
  {
    pathname: '/candidate/application/sent',
    titleBreadcrumb: 'Candidatures > Envoyées',
  },
  {
    pathname: '/candidate/application/accepted',
    titleBreadcrumb: 'Candidatures > Retenues',
  },
  {
    pathname: '/candidate/application/declined',
    titleBreadcrumb: 'Candidatures > Déclinées',
  },
  {
    pathname: '/candidate/profile',
    titleBreadcrumb: 'Mon compte > Mes informations',
  },
  {
    pathname: '/candidate/password',
    titleBreadcrumb: 'Mon compte > Mot de passe',
  },
  {
    pathname: '/recruiter/invoice',
    titleBreadcrumb: 'Mon compte > Mes factures',
  },
  {
    pathname: '/candidate/searchjob',
    titleBreadcrumb: 'Trouver mon job > Rechercher',
  },
  {
    pathname: '/candidate/saved-search',
    titleBreadcrumb: 'Trouver mon job > Recherches enregistrées',
  },
  {
    pathname: '/candidate/offer/favorite',
    titleBreadcrumb: 'Trouver mon job > Offres d\'emploi enregistrées',
  },
  {
    pathname: '/candidate/message/new',
    titleBreadcrumb: 'Messagerie > Nouveau message',
  },
  {
    pathname: '/candidate/message/received',
    titleBreadcrumb: 'Messagerie > Messages reçus',
  },
  {
    pathname: '/candidate/message/sent',
    titleBreadcrumb: 'Messagerie > Messages envoyés',
  },
  {
    pathname: '/candidate/message/archived',
    titleBreadcrumb: 'Messagerie > Messages archivés',
  },
  {
    pathname: '/candidate/agenda/interviews',
    titleBreadcrumb: 'Agenda > Mes prochains entretiens'
  },

  // {
  //   pathname: '/recruiter/login',
  //   titleBreadcrumb: 'Authentification > Connexion',
  // },
  // {
  //   pathname: '/recruiter/register',
  //   titleBreadcrumb: 'Authentification > Inscription',
  // },
  // {
  //   pathname: '/recruiter/forgot-password',
  //   titleBreadcrumb: 'Authentification > Mot de passe oublié',
  // },
  {
    pathname: '/recruiter/myoffer',
    titleBreadcrumb: 'Publications > Annonces publiées',
  },
  {
    pathname: '/recruiter/myoffer/create',
    titleBreadcrumb: 'Publications > Nouvelle annonce',
  },
  {
    pathname: '/recruiter/myoffer/draft',
    titleBreadcrumb: 'Publications > Brouillons',
  },

  {
    pathname: '/recruiter/search-cv',
    titleBreadcrumb: 'CVthèque > Recherche de profil',
  },
  {
    pathname: '/recruiter/application/received',
    titleBreadcrumb: 'Recrutement > CV reçus',
  },
  {
    pathname: '/recruiter/application/selected',
    titleBreadcrumb: 'Recrutement > CV sélectionnés',
  },
  {
    pathname: '/recruiter/application/accepted',
    titleBreadcrumb: 'Recrutement > CV retenus',
  },
  {
    pathname: '/recruiter/application/declined',
    titleBreadcrumb: 'Recrutement > CV déclinés',
  },
  {
    pathname: '/recruiter/profile',
    titleBreadcrumb: 'Mon compte > Mes informations',
  },
  {
    pathname: '/recruiter/password',
    titleBreadcrumb: 'Mon compte > Mot de passe',
  },
  {
    pathname: '/recruiter/message/new',
    titleBreadcrumb: 'Messagerie > Nouveau message',
  },
  {
    pathname: '/recruiter/message/received',
    titleBreadcrumb: 'Messagerie > Messages reçus',
  },
  {
    pathname: '/recruiter/message/sent',
    titleBreadcrumb: 'Messagerie > Messages envoyés',
  },
  {
    pathname: '/recruiter/message/archived',
    titleBreadcrumb: 'Messagerie > Messages archivés',
  },
  {
    pathname: '/recruiter/agenda/list',
    titleBreadcrumb: 'Agenda > Mes entretiens',
  },
  {
    pathname: '/recruiter/agenda/schedule-meeting',
    titleBreadcrumb: 'Agenda > Planifier un entretien',
  },
]

const topMenuItems = [
  {
    // title: 'Jobs',
    title: '',
    path: '/404',
  },
  {
    // title: 'Training',
    title: '',
    path: '/404',
  },
  {
    // title: 'News',
    title: '',
    path: '/404',
  },
  {
    title: 'Our packages',
    path: '/pricing',
  },
]

export default function Header({
  title,
  recruiter,
  candidate,
  showTitle = true,
}) {
  const [isActiveMenuMobile, setIsActiveMenuMobile] = useState(false)
  const location = useLocation()
  const { pathname } = location
  const { t } = useTranslation()

  const ANONYMOUS = isAnonymous()
  const RECRUITER = isRecruiter()
  const CANDIDATE = isCandidate()

  const logo = require('./../../images/logo-mecanojob.png')
  const menu = require('./../../images/icons/hamburger-menu.svg')
  const _title =
    title ??
    (recruiter ? 'Espace Recruteur' : candidate ? 'Espace Candidat' : '')

  const currentPath = useMemo(() => pathname.split('/')[1], [pathname])

  return (
    <>
      <header className="site-header mo-left header fullwidth shadow">
        <HeaderWrapper
          className={`sticky-header main-bar-wraper navbar-expand-lg  ${
            isActiveMenuMobile && 'active-menu-mobile'
          }`}
        >
          <div className={`main-bar no-bshadow clearfix`}>
            <div className="header-content">
              <LogoHeaderWrapper className="logo-header mostion">
                <Link to={'/'}>
                  <img src={logo.default} className="logo" alt="img" />
                </Link>
              </LogoHeaderWrapper>
              <TopMenuWrapper>
                {!CANDIDATE && topMenuItems.map(
                  ({ path, title }, idx) => {
                    return (
                      <NavLink
                        exact
                        to={path}
                        key={idx}
                        activeStyle={{ color: 'var(--orange300)' }}
                        activeClassName="font-weight-bold"
                      >
                        {t(title)}
                      </NavLink>
                    )
                  }
                )}
              </TopMenuWrapper>
              <ExtraNavWrapper className="extra-nav">
                <div className="d-flex align-items-center justify-content-between">
                  {ANONYMOUS || RECRUITER ? (
                    <Link to="/recruiter/myoffer" className="pr-2">
                      <MecButton
                        isAuth={RECRUITER}
                        iconAuth={IconArrowRight}
                        widthIcon={RECRUITER ? 8 : 24}
                        icon={BriefCase}
                        type={
                          currentPath === 'recruiter' || RECRUITER
                            ? 'primary'
                            : 'secondary'
                        }
                      >
                        RECRUTEUR
                      </MecButton>
                    </Link>
                  ) : null}

                  {ANONYMOUS || CANDIDATE ? (
                    <Link to="/candidate/mycv" className="pr-2">
                      <MecButton
                        isAuth={CANDIDATE}
                        iconAuth={IconArrowRight}
                        widthIcon={CANDIDATE ? 8 : 24}
                        icon={UserCircle}
                        type={
                          currentPath === 'candidate' || CANDIDATE
                            ? 'primary'
                            : 'secondary'
                        }
                      >
                        CANDIDAT
                      </MecButton>
                    </Link>
                  ) : null}

                  {!productionMode() && !ANONYMOUS ? (
                    <Link to="/" className="pr-2">
                      <MecButton
                        isAuth={CANDIDATE || RECRUITER}
                        iconAuth={LogoutIcon}
                        widthIcon={24}
                        minWidth={40}
                        width={40}
                        onClick={logout}
                      />
                    </Link>
                  ) : null}
                </div>
              </ExtraNavWrapper>
              <img
                src={menu.default}
                className="menu-icon"
                alt="img"
                onClick={() => setIsActiveMenuMobile((prevState) => !prevState)}
              />
            </div>
          </div>
          {showTitle && (
            <div className="row blue-sub-header">
              {!ANONYMOUS && (
                <div className="orange-recruiter-sub-header col-5 text-white text-uppercase">
                  {_title}
                </div>
              )}
              <div className="breadcrumb-sub-header">
                {breadcrumb.map(
                  (content) =>
                    pathname === content.pathname && content.titleBreadcrumb
                )}
              </div>
            </div>
          )}
        </HeaderWrapper>
      </header>
    </>
  )
}
