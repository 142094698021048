import React from 'react'
import bgrecruiter from 'src/images/gallery/background-recruiter.svg'

import * as S from 'src/markup/Layout/AuthLayout/styles'
import Header from 'src/markup/Layout/Header'

const AuthLayout = ({ children, isCandidate, bg, ...rest }) => {
  return (
    <S.Wrapper>
      <Header {...rest} showTitle={false} />
      <div
        className=''>
        <div className='section-full'>
          <div className='container-fluid'>
            <div
              className={`row height-layout ${isCandidate && 'flex-row-reverse'}`}>
              <div className='col-xl-6 col-lg-5 col-12 d-flex box-skew1'>
                <img
                  src={bg || bgrecruiter}
                  alt='Recruiter-BG'
                  className='w-100 h-100 p-5'
                  style={{ objectFit: 'contain' }}
                />
              </div>
              <div
                className={`col-xl-6 col-lg-7 col-12 box-skew d-flex py-4 ${
                  isCandidate ? 'bg-blue' : 'bg-orange'
                }`}
              >
                <div
                  className='login-orange-form seth d-flex align-self-center m-auto wow fadeInRight'
                  data-wow-delay='0.8s'
                >
                  <div className='tab-content'>
                    <S.SForm>
                      {children}
                    </S.SForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}

export default AuthLayout
