import React, { useCallback, useEffect, useGlobal, useState } from "reactn"
import { Link } from "react-router-dom"
import { FormGroup } from "reactstrap"
import { filter } from "lodash"
import Header from "src/markup/Layout/Header"
import SidebarRecruiter from "src/markup/Layout/SidebarRecruiter"
import Footer from "src/markup/Layout/Footer"
import * as S from "./styles"
import Card from "./Card"
import { MecButton } from "src/markup/Components"
import { MecSelector } from "src/markup/Components/Forms"
import { SelectCV } from "src/markup/Pages/SearchPage/Candidate/components"
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"
import { ReactComponent as IconChecked } from "src/images/icons/icon-checked-outline.svg"
import { apiEditStatusCv, getCvsInfos, recruiterGetListOffers } from "src/services/api"
import { OFFER_STATUS, SORT_BY } from "src/constants"
import { populateOffer } from "src/services/config"
import { Loading } from "src/markup/Element/Loading"
import { ConfirmModal } from "src/markup/Components/Modals"

const TITLES_BY_MODE = {
  received: "CV Reçus",
  declined: "CV Refusés",
  accepted: "CV Retenus",
  selected: "CV Séléctionnés",
}

const TITLES_BUTTON_BY_MODE = {
  received: {
    left: "Retenir",
    right: "Refuser",
  },
  selected: {
    right: "Supprimer",
  },
  accepted: { left: "Proposer un RDV",
              right: "Refuser",
  },
  declined: { right: "Supprimer" },
}

const QUERIES_BY_MODE = {
  received: {
    selected: false,
    accepted: false,
    rejected: false,
    hidden: false,
  },
  selected: {
    selected: true,
    accepted: false,
    rejected: false,
    hidden: false,
  },
  accepted: {
    selected: false,
    accepted: true,
    rejected: false,
    hidden: false,
  },
  declined: {
    selected: false,
    accepted: false,
    rejected: true,
    hidden: false,
  },
}

const STATUS_BY_ACTION = {
  Refuser: {
    rejected: true,
    accepted: false,
    NewVue: true,
  },
  "Retenir": {
    accepted: true,
    rejected: false,
    NewVue: true,
  },
  "Supprimer": {
    hidden: true,
  },
  "CV reçus": {
    accepted: false,
    rejected: false,
    selected: false,
  },
  "CV sélectionnés": {
    accepted: false,
    rejected: false,
    selected: true,
  },
}

const WARNING_BY_MODE = {
  received: "Êtes-vous sûr de vouloir refuser ce CV ?",
  selected: "Êtes-vous sûr de vouloir supprimer ce CV ?",
  accepted: "Êtes-vous sûr de vouloir refuser ce CV ?",
  declined: "Êtes-vous sûr de vouloir supprimer ce CV ?",
}

const backOptions = [
  {
    label: "CV reçus",
    id: "CV reçus",
  },
  {
    label: "CV retenus",
    id: "Retenir",
  },
]

export default function RecruiterApplicationContainer({ mode }) {
  const [cvs, setCvs] = useState([])
  const [view, setView] = useState([])
  const [offers, setOffers] = useState([])
  const [loading, setLoading] = useState("")

  const [user] = useGlobal("user")
  const [config] = useGlobal("config")

  const [modal, setModal] = useState({ showConfirmModal: false })

  const handleGetCvsInfos = useCallback(async () => {
    setLoading("getList")
    const res = await getCvsInfos({
      recruitersId: user?.id,
      query: QUERIES_BY_MODE[mode],
    })
    setCvs(res)
    setLoading("")
  }, [mode, user?.id])

  useEffect(() => {
    handleGetCvsInfos()
  }, [handleGetCvsInfos])

  const getListOffers = useCallback(async () => {
    const offers = await recruiterGetListOffers({
      status: OFFER_STATUS.PUBLISHED,
      sort_by: SORT_BY.DESC,
    })

    const offerLiaisons = offers?.filter((offer) => offer.liaisons.length)
    const offerSelector = offerLiaisons?.map((offerLiaison) => {
      const { jobNames } = populateOffer(config, offerLiaison)

      return { id: offerLiaison.id, label: jobNames?.[0] }
    })

    setOffers(offerSelector)
  }, [config])

  useEffect(() => {
    getListOffers()
  }, [getListOffers])

  const handleStatusCV = useCallback(async ({ cvId, offerId, recruiterId, candidateId, action }) => {
    try {
      setLoading(action)
      await apiEditStatusCv({ cvId, offerId, recruiterId, candidateId, query: STATUS_BY_ACTION[action] })
      setLoading("")
      handleGetCvsInfos()
      setModal({ showConfirmModal: false })
    } catch (e) {
      setLoading("")
      return e
    }
  }, [handleGetCvsInfos])

  const handleFilterCvByOfferId = async (id) => {
    setLoading("getList")

    const res = await getCvsInfos({
      recruitersId: user?.id,
      query: QUERIES_BY_MODE[mode],
    })
    const filterList = filter(res, (cv) => cv.offerId === id)
    setCvs(filterList)
    setLoading("")
  }

  const handleViewDetail = (id) => setView(id === view ? "" : id)

  const onRejectCV = useCallback(async (values) => {
    await handleStatusCV(values);
  }, [handleStatusCV])

  const showRejectCvModal = useCallback(async (values, description) => {
    setModal({
      showConfirmModal: true,
      onYes: () => onRejectCV(values),
      onNo: () => setModal({ showConfirmModal: false }),
      Description: description,
    })
  }, [onRejectCV])

  return (
    <div className="page-wraper">
      <Header recruiter />

      <div className="container-content">
        <div className="d-flex" style={{ flexWrap: "nowrap" }}>
          <SidebarRecruiter />

          <S.Content className="col-11 col-xl container">
            {loading === "getList" && <Loading />}
            <div className="d-flex justify-content-center">
              <h1 className="m-b5 text-uppercase text-orange pt-5">
                {TITLES_BY_MODE[mode]} {`(${cvs?.length || 0})`}
              </h1>
            </div>
            <hr className="v3-separator" />
            {mode !== "selected" && (
              <S.Filter className="row justify-content-end">
                <FormGroup className="d-flex flex-column col-6">
                  <div className="label text-align-last-left label-required mr-2 pt-3">Filtrer par offre d'emploi</div>
                  <div style={{ flex: 1 }}>
                    <MecSelector
                      options={offers}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      className="text-align-last-left"
                      onChange={(option) => {
                        handleFilterCvByOfferId(option.id)
                      }}
                    />
                  </div>
                </FormGroup>
              </S.Filter>
            )}
            {cvs.map((cv) => (
              <div key={cv.id}>
                <Card mode={mode} cv={cv} view={view} handleViewDetail={handleViewDetail} />
                <div className="d-flex justify-content-center mt-5">
                  {(mode === "received" || mode === "accepted") && (
                    // || mode === "accepted" = Proposer un RDV
                    <Link to="/recruiter/agenda/schedule-meeting">
                      <MecButton
                        icon={IconChecked}
                        height={44}
                        width={250}
                        type="primary"
                        className="site-button site-button-gap radius-xl custom-btn-footer"
                        disabled={loading}
                        onClick={mode === "received" ? (e) => {
                          e.preventDefault()
                          handleStatusCV({
                            cvId: cv.cvId,
                            offerId: cv.offerId,
                            recruiterId: cv.recruiterId,
                            candidateId: cv.candidateId,
                            action: TITLES_BUTTON_BY_MODE[mode].left,
                          })
                        } : null}
                      >
                        {loading === TITLES_BUTTON_BY_MODE[mode].left ? "..." : TITLES_BUTTON_BY_MODE[mode].left}
                      </MecButton>
                    </Link>
                  )}
                  {mode === "declined" && (
                    <SelectCV
                      placeholder="Remettre dans :"
                      className="h-44"
                      options={backOptions}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ id }) => id}
                      onChange={(option) =>
                        handleStatusCV({
                          cvId: cv.cvId,
                          offerId: cv.offerId,
                          recruiterId: cv.recruiterId,
                          candidateId: cv.candidateId,
                          action: option.id,
                        })
                      }
                    />
                  )}

                  <MecButton
                    icon={IconCancel}
                    height={44}
                    width={250}
                    type="primary"
                    className="site-button site-button-gap radius-xl custom-btn-footer ml-4"
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault()
                      showRejectCvModal({
                        cvId: cv.cvId,
                        offerId: cv.offerId,
                        recruiterId: cv.recruiterId,
                        candidateId: cv.candidateId,
                        action: TITLES_BUTTON_BY_MODE[mode].right,
                      },
                      WARNING_BY_MODE[mode])
                    }}
                  >
                    {loading === TITLES_BUTTON_BY_MODE[mode].right ? "..." : TITLES_BUTTON_BY_MODE[mode].right}
                  </MecButton>
                </div>
              </div>
            ))}
          </S.Content>
        </div>
      </div>

      <ConfirmModal {...modal} />

      <Footer />
    </div>
  )
}
