import React, { useState, useEffect } from "reactn"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { MecAutocompleteLocation, MecInput, MecSelector } from "src/markup/Components/Forms"
import { MecDatePicker } from "src/markup/Components/MecDatePicker"
import { ProfileButton } from "src/markup/Pages/Profile/components/ProfileButton"
import { onUpdateProfile, useGetProfile } from "../useGetProfile"
import * as S from "./styles"
import { validationProfileCandidate } from "./validationSchema"
import { Loading } from "src/markup/Element/Loading"
import UserAvatarIcon from "src/images/icons/user-avatar.svg"
import { uploadImage } from "src/services/api"
import { STRING_IS_BASE64_REG_EXP } from "src/constants"
import { extractFromAddress } from "src/utils"
import { cacheUser } from "src/services/auth"

export const genders = {
  m: { value: "m", label: "M." },
  f: { value: "f", label: "Mme." },
}

const LANGUAGES = [{ value: "Français", label: "Français" }, { value: "Anglais", label: "Anglais" }]

export const CandidateInformation = () => {
  const { t } = useTranslation()

  const profile = useGetProfile()
  const [loading, setLoading] = useState("")
  const [avatar, setAvatar] = useState("")

  const {
    gender,
    surname,
    name,
    email,
    phonenumber,
    birthday,
    language,
    address,
    postalcode,
    city,
    country,
    profilepicture,
  } = profile

  const initialValues = {
    gender: genders[gender],
    surname,
    name,
    email,
    phonenumber,
    birthday: birthday && birthday !== "Invalid date" ? new Date(birthday) : null,
    language,
    address,
    postalcode,
    city,
    country,
    image_url: profilepicture,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: () => validationProfileCandidate({ t }),
    onSubmit: async (values) => {
      const payload = {
        ...values,
        gender: values.gender?.value,
        birthday: new Date(values.birthday),
      }
      await onUpdateProfile(payload)
      cacheUser(payload)
    },
  })

  const { setFieldValue, isSubmitting, handleBlur } = formik

  const handleUploadLogo = async (e) => {
    e.preventDefault()
    const { type } = e.target.files[0]
    var reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = async () => {
      setAvatar(reader.result)
      setLoading("upload")
      const res = await uploadImage({
        image: {
          name: "Avatar",
          extension: type,
          base64: reader.result.split(",")[1],
        },
      })
      setFieldValue("image_url", res.data)
      setLoading("")
    }
  }

  useEffect(
    () => {
      setAvatar(profilepicture)
    },
    [profilepicture]
  )

  return (
    <S.Wrapper>
      <S.Heading>MES INFORMATIONS PERSONNELLES</S.Heading>

      <hr className="v3-separator pb-xl-3"></hr>

      <S.WrapperContent>
        <div>
          <S.Title>Présentez-vous</S.Title>

          <div className="row flex-md-row-reverse">
            <div className="col-lg-6 col-12">
              <S.UploadImage>
                {loading === "upload" && <Loading />}
                <S.Image avatar={avatar}>
                  <img
                    src={
                      (avatar && (avatar?.match(STRING_IS_BASE64_REG_EXP) ? URL.createObjectURL(avatar) : avatar)) ||
                      UserAvatarIcon
                    }
                    alt="avatar"
                  />
                  <input
                    placeholder="Description"
                    type="file"
                    name="file"
                    onChange={(e) => {
                      handleUploadLogo(e)
                    }}
                  />
                </S.Image>
              </S.UploadImage>
            </div>
            <div className="col-lg-6 col-12">
              <div className="py-2 label label-required">
                Civilité
                <S.Asterisk>*</S.Asterisk>
              </div>
              {/* TODO: update React-select placeholder with custom value instead of default 'Select...' one */}
              {/* TODO: inject API value if already exists in the select */}
              <MecSelector
                options={Object.values(genders)}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ value }) => value}
                className="text-align-last-left"
                name="gender"
                placeholder="Sélectionnez..."
                colorTxtError="red"
                onChange={(option) => {
                  formik.setFieldValue("gender", option)
                }}
                onBlur={() => formik.setFieldTouched("gender", true)}
                value={formik.values?.gender}
                errors={formik.errors?.gender?.value}
                touched={formik.touched?.gender}
              />
            </div>
          </div>

          <div className="row justify-content-between">
            {[
              { label: "Prénom", name: "surname" },
              { label: "Nom", name: "name" },
              { label: "Adresse e-mail", name: "email", disabled: true },
              { label: "Téléphone", name: "phonenumber" },
            ].map((item, idx) => (
              <div key={idx} className="col-lg-6 col-12">
                <div className="py-2 label label-required">
                  {item.label}
                  <S.Asterisk>*</S.Asterisk>
                </div>
                <MecInput
                  name={item.name}
                  formik={formik}
                  disabled={item.disabled}
                  className="secondary"
                  style={{ minWidth: "auto" }}
                />
              </div>
            ))}
          </div>

          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="py-2 label label-required">
                Date de naissance
                <S.Asterisk>*</S.Asterisk>
              </div>
              <MecDatePicker
                name="birthday"
                onChange={(value) => {
                  setFieldValue("birthday", value)
                }}
                handleBlur={handleBlur}
                errors={formik.errors?.birthday}
                touched={formik.touched?.birthday || formik.touched.expEntryDate}
                selected={formik.values?.birthday && new Date(formik.values?.birthday)}
                value={formik.values?.birthday && new Date(formik.values?.birthday)}
                autoComplete="off"
              />
            </div>

            <div className="col-lg-6 col-12">
              <div className="py-2 label label-required">
                Langue préférée
                <S.Asterisk>*</S.Asterisk>
              </div>

              <S.SelectComponent
                className="basic-single"
                classNamePrefix="select"
                defaultValue={LANGUAGES[0]}
                name="language"
                onChange={(option) => formik.setFieldValue("language", option.value)}
                options={LANGUAGES}
              />
            </div>
          </div>

          <S.Title className="pt-4">Votre adresse</S.Title>
          <div className="row justify-content-between">
            <div className="col-lg-6 col-12">
              <div className="py-2 label label-required">
                Numéro et nom de rue
                <S.Asterisk>*</S.Asterisk>
              </div>
              <MecAutocompleteLocation
                placeholder={"Entrez le nom d'une ville"}
                formik={formik}
                name="address"
                className="secondary w-100"
                style={{ height: 45, minWidth: "auto" }}
                onChange={(e) => {
                  const { value } = e.target
                  formik.setFieldValue("address", value)
                }}
                onPlaceSelected={(address) => {
                  const city = extractFromAddress(address.address_components, "locality")
                  const streetNumber = extractFromAddress(address.address_components, "street_number")
                  const route = extractFromAddress(address.address_components, "route")
                  const country = extractFromAddress(address.address_components, "country")
                  const postalcode = extractFromAddress(address.address_components, "postal_code")
                  if (city) formik.setFieldValue("city", city)
                  if (country) formik.setFieldValue("country", country)
                  if (streetNumber || route) formik.setFieldValue("address", `${streetNumber || ""} ${route || ""}`)
                  if (postalcode) formik.setFieldValue("postalcode", postalcode)
                }}
                useAddress={true}
              />
              {/* <MecInput name="address" formik={formik} className="secondary" style={{ minWidth: "auto" }} /> */}
            </div>
            <div className="col-lg-6 col-12">
              <div className="py-2 label label-required">
                Code postal
                <S.Asterisk>*</S.Asterisk>
              </div>
              <MecInput name="postalcode" formik={formik} className="secondary" style={{ minWidth: "auto" }} />
            </div>
            <div className="col-lg-6 col-12">
              <div className="py-2 label label-required">
                Ville
                <S.Asterisk>*</S.Asterisk>
              </div>
              <MecInput name="city" formik={formik} className="secondary" style={{ minWidth: "auto" }} />
            </div>
            <div className="col-lg-6 col-12">
              <div className="py-2 label label-required">
                Pays
                <S.Asterisk>*</S.Asterisk>
              </div>
              <MecInput name="country" formik={formik} className="secondary" style={{ minWidth: "auto" }} />
            </div>
          </div>
        </div>
      </S.WrapperContent>

      <S.TextNote>Tous les champs de ce formulaire sont obligatoires</S.TextNote>
      <ProfileButton
        className="offset-lg-7 offset-md-6 offset-0"
        isSubmitting={isSubmitting}
        onSubmit={formik.handleSubmit}
      />
    </S.Wrapper>
  )
}
