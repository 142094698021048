import React, { useGlobal, useEffect, useCallback, useState } from "reactn"
import Header from "src/markup/Layout/Header"
import { FormGroup, Form } from "reactstrap"
import styled from "styled-components"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"
import moment from "moment"
import { toast } from "react-toastify"
import { isEmpty } from 'lodash'
import * as Yup from "yup"

import * as S from "./styles"
import Footer from "src/markup/Layout/Footer"
import { MecInput, MecSelectorCustom } from "src/markup/Components/Forms"
import { MecButton } from "src/markup/Components"
import { ReactComponent as IconSend } from "src/images/icons/send.svg"
import { ReactComponent as IconCancel } from "src/images/icons/icon-cancel.svg"
import SidebarCandidate from "src/markup/Layout/SidebarCandidate"
import { getListContacts, sendMessage } from "src/services/api"
import { FORMAT_DATE } from "src/constants"

const Content = styled(Form)`
  margin-bottom: 50px;
  margin-top: 65px;
  padding: 0 20px;
  text-align-last: center;

  @media (min-width: 992px) {
    padding: 0 50px;
  }
`

const New = () => {
  const [contacts, setContacts] = useState([])

  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation();
  const [user] = useGlobal("user")

  const getLists = useCallback(async () => {
    try {
      const contacts = await getListContacts()
      setContacts(contacts.filter(contact => contact.email !== user.email))
    } catch (e) {
      return e
    }
  }, [user.email])

  useEffect(() => {
    getLists()
  }, [getLists])

  const formik = useFormik({
    initialValues: {
      user_receive_id: !isEmpty(location?.state) ? location?.state  : null,
      object_title: "",
      content_text: "",
    },
    enableReinitialize: true,
    validationSchema: () =>
      Yup.object().shape({
        user_receive_id: Yup.object().required(t("required_field")).nullable(true),
        object_title: Yup.string(t("invalid_field")).required(t("required_field")),
        content_text: Yup.string(t("invalid_field")).required(t("required_field")),
      }),
    onSubmit: async (values) => {
      try {
        values.user_receive_id = values.user_receive_id.id

        const res = await sendMessage(values)
        toast.success(res.message)
        history.push("/candidate/message/sent")
      } catch (error) {
        return errors
      }
    },
  })

  const { errors, touched, values, setFieldValue, setFieldTouched, handleSubmit, isSubmitting } = formik

  return (
    <div className="page-wraper">
      <Header candidate />

      <div className="container-content">
        <div className="d-flex" style={{ flexWrap: "nowrap" }}>
          <SidebarCandidate />

          <Content className="col-11 col-xl" onSubmit={handleSubmit}>
          <div className="d-flex justify-content-center">
            <S.Heading>Nouveau message</S.Heading>
          </div>
          <hr className="v3-separator pb-xl-3" />

            <S.MessageNew>
              <S.Header>
                <div className="row">
                  <FormGroup className="col-4 d-flex">
                    <div className="label text-align-last-left label-required mr-2 pt-3">À :</div>
                    <div style={{ flex: 1, fontSize: "90%"}}>
                      <MecSelectorCustom
                        options={contacts}
                        getOptionLabel={({ email }) => email}
                        getOptionValue={({ id }) => id}
                        className="text-align-last-left"
                        colorTxtError="red"
                        name="user_receive_id"
                        placeholder="Sélectionnez..."
                        onChange={(option) => {
                          setFieldValue("user_receive_id", {
                            id: option.id,
                            email: option.email
                          })
                        }}
                        onBlur={() => setFieldTouched("user_receive_id", true)}
                        value={values.user_receive_id}
                        errors={errors.user_receive_id}
                        touched={touched.user_receive_id}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="col-5 d-flex">
                    <div className="label text-align-last-left label-required mr-2 pt-3">Objet :</div>
                    <div style={{ flex: 1 }}>
                      <MecInput
                        className="text-align-last-left secondary"
                        name="object_title"
                        formik={formik}
                        colorTxtError="red"
                        style={{ minWidth: "auto" }}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="col-3 d-flex pt-3">
                    <div className="label text-align-last-left label-required mr-2">Date :</div>
                    <div style={{ flex: 1 }}>{moment().format(FORMAT_DATE)}</div>
                  </FormGroup>
                </div>
              </S.Header>
              <S.Content className="row">
                <hr />

                <FormGroup className="col-12 m-0">
                  <div style={{ flex: 1 }}>
                    <MecInput
                      formik={formik}
                      className="text-align-last-left"
                      rows="10"
                      cols="100"
                      as="textarea"
                      name="content_text"
                      placeholder="Rédiger votre message..."
                    />
                  </div>
                </FormGroup>
              </S.Content>

              <S.Footer className="row justify-content-end">
                <MecButton
                  icon={IconSend}
                  height={44}
                  width={200}
                  type="primary"
                  className="site-button site-button-gap radius-xl change-fill"
                >
                  {isSubmitting ? "..." : "Envoyer"}
                </MecButton>
                <MecButton
                  icon={IconCancel}
                  height={44}
                  width={200}
                  type="primary"
                  className="site-button site-button-gap radius-xl"
                  onClick={() => history.push("/candidate/message/sent")}
                >
                  Annuler
                </MecButton>
              </S.Footer>
            </S.MessageNew>
          </Content>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default New
