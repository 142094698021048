import React, { memo, useGlobal, useLayoutEffect, useRef, useState, Fragment } from "reactn"
import { keyBy, values } from "lodash"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { FormSearchCV, ListCardCV } from "src/markup/Pages/SearchPage/Recruiter/components"
import { useMainContext } from "src/markup/Layout/MainLayout/MainContext"
import { getListSavedSearch, deleteSavedSearch, updateSavedSearch } from "src/services/api"
import { MecButton } from "src/markup/Components"
import { ReactComponent as IconCreate } from "src/images/icons/note.svg"
import * as S from "./styles"

export const SavedSearchCV = memo(({ site }) => {
  const [config] = useGlobal("config")
  const history = useHistory()
  const { isShowResult } = useMainContext()

  const refResult = useRef(null)
  const [formList, setFormList] = useState({})

  const _getListSavedSearch = async () => {
    const { data } = await getListSavedSearch()
    setFormList(keyBy(data, (el) => el.id))
  }

  useLayoutEffect(() => {
    _getListSavedSearch()
    if (isShowResult && refResult.current) {
      refResult.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      })
    }
  }, [isShowResult])

  if (!config) return null

  const onDelete = async (id) => {
    const { message } = await deleteSavedSearch(id)
    toast.success(message, {
      autoClose: 2000,
      theme: "colored",
    })
    delete formList[id]
    setFormList({ ...formList })
  }

  const onUpdate = async (id, newData) => {
    const { message } = await updateSavedSearch(id, {
      data_save_search: newData,
      name_search: [
        newData?.joblistId?.name,
        newData?.city
      ].filter(Boolean).join(', '),
    });
    toast.success(message, {
      autoClose: 2000,
      theme: "colored",
    })
    setFormList({ ...formList })
    history.go(0);
  }

  return (
    <S.Wrapper>
      <div className="d-flex justify-content-center">
        <S.Heading>Recherches sauvegardées ({values(formList).length})</S.Heading>
      </div>
      <hr className="v3-separator pb-xl-3" />
      {values(formList).map((formItem, idx) => (
        <Fragment>
          <FormSearchCV
            key={formItem.id}
            heading="RECHERCHES SAUVEGARDÉES"
            onDelete={onDelete}
            onUpdate={onUpdate}
            formItem={formItem}
            site={site}
          />
          {isShowResult && (
            <div ref={refResult} className="pt-5">
              <ListCardCV />
            </div>
          )}
        </Fragment>
      ))}
      {values(formList).length === 0 ?
        <div className="row justify-content-center">
          <MecButton
            icon={IconCreate}
            width={350}
            height={44}
            type="primary"
            className="site-button site-button-gap radius-xl"
            onClick={() => history.push("/recruiter/search-cv")}
          >
            Créer une recherche de profil
          </MecButton>
        </div>
      : null}
    </S.Wrapper>
  )
})
