import styled from "styled-components";

export const UrgentCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 40px;
`;

export const CardWrapper = styled.div`
  width: 100%;
  min-width: 350px;
  border: 5px solid #ff7200;
  border-radius: 30px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1024px) {
    width: calc(100% / 3 - 40px);
    max-width: 420px;
  }
`;

export const CardLogo = styled.div`
  height: auto;
  max-height: 120px;
  img {
    object-fit: cover;
  }
  @media (max-width: 980px) {
    max-height: 150px;
    img {
      object-fit: contain;
    }
  }
`;

export const CardTitle = styled.div`
  text-align: center;
  padding: 30px 0;
  border-bottom: 3px solid #e8eef2;

  div {
    font-weight: 800;
    font-size: 17px;
    line-height: 20px;
    color: #023f73;
  }

  div:last-child {
    font-weight: 900;
    font-size: 28px;
    line-height: 35px;
  }
`;

export const CardContent = styled.div`
  padding: 20px 0;
  border-bottom: 3px solid #e8eef2;

  div {
    font-weight: 800;
    font-size: 18.4202px;
    line-height: 22px;
    color: #023f73;
  }

  div:last-child {
    font-weight: 400;
    font-size: 14.7361px;
    line-height: 17px;
    margin-top: 15px;
  }
`;

export const CardInfor = styled.div`
  padding: 30px 0;

  ul {
    list-style-type: none;
  }
`;

export const CardAction = styled.div``;
