import React, { useMemo } from "reactn"
import { useFormik } from "formik"
import { ReactComponent as Search } from "src/images/icons/search.svg"
import { ReactComponent as ClockIcon } from "src/images/icons/clock-icon.svg"
import { ReactComponent as LocationIcon } from "src/images/icons/location-icon.svg"
import { ReactComponent as ScheduleIcon } from "src/images/icons/schedule-icon.svg"
import { ReactComponent as TickCircleIcon } from "src/images/icons/tick-circle.svg"
import { ReactComponent as RemoveCircleIcon } from "src/images/icons/remove-circle.svg"
import { ReactComponent as TimeCircleIcon } from "src/images/icons/time-circle.svg"
import { ReactComponent as MailCircleIcon } from "src/images/icons/mail-circle.svg"
import { ReactComponent as Trash } from "src/images/icons/trash.svg"
import { ReactComponent as Wrench } from "src/images/icons/wrench.svg"
import { get } from "lodash"
import { MecButton } from "src/markup/Components"
import { MecInput } from "src/markup/Components/Forms"
import * as S from "./styles"
import { getJobById, parseDateTime } from "src/services/config"
import { AGENDA_STATUS } from "src/constants"
import { useHistory } from "react-router-dom"

const RenderStatus = ({ status, email }) => {
  const history = useHistory()
  switch (status) {
    case AGENDA_STATUS.WAITING:
      return (
        <div className="col-3 d-flex align-items-center ">
          <TimeCircleIcon width={35} height={35} className="cursor-pointer" />
          <MailCircleIcon
            onClick={() =>
              history.push({
                pathname: "/recruiter/message/new",
                state: { email },
              })
            }
            width={35}
            height={35}
            className="ml-2 cursor-pointer"
          />
        </div>
      )
    case AGENDA_STATUS.ACCEPTED:
      return (
        <div className="col-3">
          <TickCircleIcon width={35} height={35} />
        </div>
      )
    case AGENDA_STATUS.REJECTED:
      return (
        <div className="col-3">
          <RemoveCircleIcon width={35} height={35} />
        </div>
      )
    default:
      break
  }
}

export const Card = ({ isPrefix, agenda, config, onDelete }) => {
  const history = useHistory()
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
  })

  const getJobInfo = useMemo(() => {
    if (config && agenda) {
      const options = {
        config,
        otherJobId: get(agenda, "offer.joblistJobs[0].otherJobId", null),
        jobId: get(agenda, "offer.joblistJobs[0].mainJobId", null),
      }
      const data = getJobById(options)

      return data
    }

    return null
  }, [agenda, config])

  return (
    <S.Wrapper isPrefix={isPrefix}>
      <div className="d-flex justify-content-between border-bottom-5 pb-2">
        <div className="col-8 d-flex align-items-center px-0">
          <S.WrapperShipIcon />
          <S.TextOrange className="pl-2" bold>
            {`${getJobInfo?.jobName} - ${getJobInfo?.otherJobName}`}
          </S.TextOrange>
        </div>
        <div className="row col-4 justify-content-end align-items-center">
          <div style={{ height: 40 }} className="w-100">
            <MecButton
              icon={Search}
              widthIcon={18}
              width="100%"
              height={35}
              onClick={() => history.push(`/offer/${agenda?.offer?.id}`)}
            >
              Voir l'annonce
            </MecButton>
          </div>
        </div>
      </div>

      <div className="row py-2 justify-content-between">
        <div className="col-8 row align-items-center">
          <ScheduleIcon width={20} />
          <S.TextOrange bold className="pl-2" size={30}>
            {parseDateTime(get(agenda, "time", new Date()))}
          </S.TextOrange>
        </div>
        <div className="col-4 d-flex align-items-center justify-content-end">
          <ClockIcon width={18} />
          <S.TextBlue className="pl-2" bold>
            Durée : {get(agenda, "duration", "-")}
          </S.TextBlue>
        </div>
      </div>

      <div className="row justify-content-between border-bottom-5 pb-2">
        <div className="col-8 row align-items-center">
          <LocationIcon width={18} />
          <S.TextBlue className="pl-2" bold>
            {agenda?.isVideo
              ? "Vidéoconférence"
              : `${get(agenda, "address", "")}-${get(agenda, "address1", "")}-${get(agenda, "address2", "")}`}
          </S.TextBlue>
        </div>
      </div>

      <div className="py-4">
        <div className="d-flex pb-1">
          <S.TextOrange size={15} bold>
            Organisateur(s) :
          </S.TextOrange>
          <S.TextBlue className="pl-1" size={15}>
            {agenda?.organizer.map((or) => `${or.name} - ${or.email}`).join(", ")}
          </S.TextBlue>
        </div>
        <div className="pb-1">
          <S.TextOrange size={15} bold>
            Candidat(s) :
          </S.TextOrange>
          {agenda?.jobliaison.map((liasion) => (
              <div key={liasion.id} className="row align-items-center">
                <div className="mt-3 position-relative col-9">
                  <MecInput disabled defaultValue={liasion?.cv?.name_cv} formik={formik} className="secondary" />
                  <S.TagSearch onClick={() => history.push(`cv/${liasion.cvId}`)}>Voir le CV</S.TagSearch>
                </div>
                <RenderStatus status={liasion.status_agenda} email={get(liasion, 'cv.user.email', '')} />
              </div>
            )
          )}
        </div>
      </div>

      <div className="d-flex pb-3">
        <S.TextOrange size={15} bold>
          Informations complémentaires :
        </S.TextOrange>
        <S.TextBlue className="pl-2" size={15}>
          {agenda?.information}
        </S.TextBlue>
      </div>

      <div className="row align-items-center offset-lg-6 offset-md-3 offset-0">
        <div className="col-md-8 col-6 pl-0">
          <MecButton
            type="primary"
            icon={Wrench}
            widthIcon={20}
            height={35}
            onClick={() => history.push(`/recruiter/agenda/schedule-meeting?agenda=${agenda.id}`)}
          >
            Modifier l'entretien
          </MecButton>
        </div>
        <div className="col-md-4 col-6 pr-0">
          <MecButton height={35} type="primary" icon={Trash} widthIcon={20} onClick={() => onDelete(agenda.id)}>
            Supprimer
          </MecButton>
        </div>
      </div>
    </S.Wrapper>
  )
}
