import React, { useState, useCallback, useEffect } from "reactn"
import Header from "src/markup/Layout/Header"
import { useHistory } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { keyBy, omit } from "lodash"

import * as S from "./styles"
import SidebarRecruiter from "src/markup/Layout/SidebarRecruiter"
import Footer from "src/markup/Layout/Footer"
import View from "./View"
import Lists from "./Lists"
import { MecButton } from "src/markup/Components"
import { ReactComponent as IconPlus } from "src/images/icons/icon-plus.svg"
import { actionMessage, getListContacts, getListMessage, readMessage, searchMessage } from "src/services/api"
import { MESSAGE, SORT_BY } from "src/constants"

const ReceivedAndSent = ({ mode }) => {
  const [messageView, setMessageView] = useState()
  const [messages, setMessages] = useState([])
  const [contacts, setContacts] = useState([])
  const [actionLoading, setActionLoading] = useState("")

  const history = useHistory()

  const getListMessages = useCallback(async () => {
    try {
      const messages = await getListMessage({
        type: MESSAGE[mode],
      })
      setMessages(messages)
    } catch (e) {
      return e
    }
  }, [mode])

  const getLists = useCallback(async () => {
    try {
      const contacts = await getListContacts()
      setContacts(contacts)
    } catch (e) {
      return e
    }
  }, [])

  useEffect(() => {
    getLists()
  }, [getLists])

  useEffect(() => {
    getListMessages()
  }, [getListMessages])

  const handleActionMessage = useCallback(async ({ e, actionType, messageId }) => {
    e.preventDefault()
    setActionLoading(actionType)

    try {
      if (actionType === 1) {
        await actionMessage({
          id: messageId,
          type_action: actionType,
          type_message: MESSAGE[mode],
        })
        toast.success("Message archivé")
      } else {
        const messagesKeyBy = keyBy(messages, "id")
        const dataRemove = omit(messagesKeyBy, messageId)

        await actionMessage({
          id: messageId,
          type_action: actionType,
          type_message: MESSAGE[mode],
        })
        setMessages(Object.values(dataRemove))
        setMessageView(null)
        toast.success("Message supprimé")
      }

      getListMessages();
      setActionLoading("")
    } catch (e) {
      setActionLoading("")
      return e
    }
  }, [getListMessages, messages, mode])

  const handleViewMessage = (message) => {
    setMessageView(message)
    readMessage(message.id)
  }

  const formik = useFormik({
    initialValues: { email: "", object: "" },
    enableReinitialize: true,
    validationSchema: () => Yup.object().shape({}),
    onSubmit: async (values) => {
      try {
        const res = await searchMessage({
          ...values,
          type: MESSAGE[mode],
          sort_by: SORT_BY.DESC,
        })
        setMessages(res.data)
      } catch (error) {
        return error
      }
    },
  })

  return (
    <div className="page-wraper">
      <Header recruiter />

      <div className="container-content">
        <div className="d-flex" style={{ flexWrap: "nowrap" }}>
          <SidebarRecruiter />

          <S.Wrapper className="col-11 col-xl">
            <div className="d-flex justify-content-center">
              <h1 className="m-b5 text-uppercase text-orange">
                {mode === "SENT" ? "Messages envoyés" : "Messages reçus"}
              </h1>
              <div className="btn-move-new">
                <MecButton
                  icon={IconPlus}
                  height={44}
                  width={200}
                  type="primary"
                  className="site-button site-button-gap radius-xl change-fill"
                  onClick={() => history.push("/recruiter/message/new")}
                >
                  Nouveau message
                </MecButton>
              </div>
            </div>
            <hr className="v3-separator pb-xl-3" />
            <Lists
              handleViewMessage={handleViewMessage}
              messageView={messageView}
              messages={messages}
              mode={mode}
              formik={formik}
              contacts={contacts}
            />
            {messageView && (
              <View
                message={messageView}
                mode={mode}
                handleActionMessage={handleActionMessage}
                actionLoading={actionLoading}
              />
            )}
          </S.Wrapper>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default ReceivedAndSent
