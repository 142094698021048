import styled, { css } from 'styled-components'
import { Form } from 'reactstrap'

const CustomLabelCommon = css`
  .label {
    font-weight: 800;
    font-size: 19px;
    line-height: 28px;
    color: #023f73;
    display: flex;
  }

  .custom-radio {
    min-width: 50px;
    margin-left: 15px;
    height: 20px;
  }

  .label-disabled {
    color: #bec7cc;
    circle {
      fill: #bec7cc;
    }
  }

  .label-required:after {
    content: '*';
    font-weight: 500;
    font-size: 53px;
    color: #ff7200;
    padding-left: 15px;
  }
`

export const StyledCVForm = styled(Form)`
  .button-action {
    gap: 20px;
  }

  ${CustomLabelCommon}
`

export const StyledForm = styled.div`
  width: 100%;
  padding: 0 50px;

  ${CustomLabelCommon}

  .add-experience {
    svg {
      transform: scale(0.7);
    }
    .label {
      font-weight: 500;
      margin-left: 15px;
    }
  }

  .sub-title {
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #023f73;

    &:before {
      content: '';
      width: 15px;
      height: 15px;
      background: #ff7200;
      border-radius: 100%;
      display: inline-block;
      margin-right: 15px;
    }
  }

  .w-250 {
    width: 250px;
  }
`

export const StyledLabel = styled.div`
  font-weight: 800;
  font-size: 19px;
  line-height: 28px;
  display: flex;

  color: ${(props) => (props.inexperienced ? '#bec7cc' : '#023f73')};
  circle {
    fill: ${(props) => props.inexperienced && '#bec7cc'};
  }

  &.label-required:after {
    content: '*';
    font-weight: 500;
    font-size: 53px;
    color: ${(props) => (props.inexperienced ? '#bec7cc' : '#ff7200')};
    padding-left: 15px;
  }
`
export const Minus = styled.div`
  display: flex;
  align-items: center;

  right: -100%;
  position: relative;
  transform: translate(-300px, 0px);

  font-weight: 800;
  font-size: 19px;
  line-height: 28px;
  color: #023f73;
`
